<template>
  <figure class="game-center-item" :class="className">
    <nuxt-link class="cursor-pointer" @click="openGame(game)">
      <div class="game-center-item__box">
        <div class="game-center-item__box--image">
          <CommonBaseImg :src="game.image" class="game-list--top" lazy :alt="game.title" />
        </div>
        <div class="game-center-item__box--title">
          <div v-if="sumJackpot[game.jackpot] > 0" class="jackpot">
            <AnimateNumberRolling :number="sumJackpot[game.jackpot]" />
          </div>
          <span>
            {{ game.title }}
          </span>
        </div>
        <div class="game-center-item__box--des">
          {{ game.description }}
        </div>
      </div>
    </nuxt-link>
  </figure>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app'
import { useJackpot } from '~/composables/game/useJackpot'
import AnimateNumberRolling from '~/components/common/animate-number-rolling.vue'
import CommonBaseImg from '~/components/common/base-img.vue'
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { currentUser, sumJackpot, jackpot } = storeToRefs(store)
const router = useRouter()

const props = defineProps({
  game: {
    type: Object,
    default: () => ({})
  },
  className: {
    type: String,
    default: ''
  },
  isShowIcon: {
    type: Boolean,
    default: false
  }
})

const openGame = (item: any) => {
  if (item.link) {
    router.push(item.link)
  }
}
</script>

<style scoped lang="scss" src="assets/scss/components/desktop/pages/home/game-center/game-center-item.scss"></style>
